import React, { useEffect, Fragment } from 'react';
import { Flex, Text, Icon, Alert, AlertIcon, useColorMode } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import CheckoutForm from './CheckoutForm';
import { HostedForm } from 'react-acceptjs';
import { number } from 'utils/utility';
import { borderColor } from 'config/data';

const authData = {
  apiLoginID: process.env.NEXT_PUBLIC_LOGIN_ID,
  clientKey: process.env.NEXT_PUBLIC_CLIENT_KEY,
};

const Step3 = ({ usdPurchaseData, onClose, onSubmit, onBack, user, nftData, access_token }) => {
  const { id, price_usd, name } = nftData;
  // console.log('access_token 3', access_token);
  // console.log('usdPurchaseData', usdPurchaseData);
  // const [type, setType] = React.useState('');
  const { colorMode } = useColorMode();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (typeof window !== 'undefined') {
        window.gtag('event', 'begin_checkout', {
          currency: 'USD',
          value: number(price_usd),
          items: [
            {
              item_id: id,
              item_name: name,
              currency: 'USD',
              price: number(price_usd),
              quantity: 1,
            },
          ],
        });
      }
    }
    return () => (isSubscribed = false);
  }, [id, name, price_usd]);

  return (
    <Fragment>
      <Flex
        py="12px"
        px="20px"
        borderBottomWidth={1}
        borderColor={borderColor[colorMode]}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Icon as={MdArrowBack} onClick={() => onBack(1)} left="20px" cursor="pointer" fontSize="22px" />
          <Text fontWeight="bold" fontSize="14px" ml="10px">
            Back
          </Text>
        </Flex>
      </Flex>
      <Flex px="20px" pt="15px" pb="15px" flexDirection="column">
        <Text fontSize="24px" fontWeight="bold" mb="5px">
          Add Credit Card
        </Text>

        <Text fontSize="12px" mb="13px">
          NFT Checkout
        </Text>

        {/* <HostedForm authData={authData} onSubmit={(values) => console.log(values)} /> */}

        <CheckoutForm
          onSuccess={onSubmit}
          nftData={nftData}
          usdPurchaseData={usdPurchaseData}
          user={user}
          access_token={access_token}
        />
      </Flex>
    </Fragment>
  );
};
export default Step3;
