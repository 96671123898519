import React, { useState, Fragment } from 'react';
import { Flex, Image, Button, Text, Checkbox, Icon } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';

const Step2 = ({ onSubmit, loading, onBack }) => {
  const [check, setCheck] = useState(false);
  const message = `While paying with crypto currency is always the preferred payment method to purchase NFTs, we understand many people don’t have a digital wallet and so we are happy to support NFT payments using a Credit Card. By moving forward, you confirm that you understand what you are buying and you stand behind your purchase. Credit Card chargeback rates are as high as 30%. Upon completion of this purchase follow email instructions to claim the NFT and transfer it to a digital wallet. ALL SALES ARE FINAL.`;
  const onCheck = (e) => {
    setCheck(e.target.checked);
  };
  return (
    <Fragment>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" minH="400px">
        <Flex py="12px" px="20px" borderBottomWidth={1} alignSelf="flex-start" w="100%" mb="30px">
          <Flex alignItems="center">
            <Icon as={MdArrowBack} onClick={() => onBack(1)} left="20px" cursor="pointer" fontSize="22px" />
            <Text fontWeight="bold" fontSize="14px" ml="10px">
              Back
            </Text>
          </Flex>
        </Flex>
        <Flex
          bg="#fafafa"
          p="8px 7px 7px 8px"
          w="90px"
          h="90px"
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
        >
          <Image src="/images/info-message.svg" w="70px" h="70px" alt="" />
        </Flex>
        <Text fontSize="16px" fontWeight="bold" mt="20px" mb="10px" px="80px" textAlign="center" color="#ef233c">
          Confirm your purchase
        </Text>
        <Text fontSize="12px" px="40px" color="#c71711" lineHeight="1.83">
          {message}
        </Text>
        <Flex mt="15px" mb="35px" alignSelf="flex-start" px="35px">
          <Checkbox defaultIsChecked={check} onChange={(e) => onCheck(e)} colorScheme="green">
            <Text fontSize="12px" fontWeight="500">
              I accept and confirm my purchase
            </Text>
          </Checkbox>
        </Flex>
      </Flex>
      <Flex py="15px" px="35px" borderTopWidth={2} justifyContent="center">
        <Button isLoading={loading} variant="black" w="100%" onClick={onSubmit} isDisabled={check ? false : true}>
          Next
        </Button>
      </Flex>
    </Fragment>
  );
};
export default Step2;
