import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Flex, useColorMode } from '@chakra-ui/react';
// import Loader from 'components/Loader';
import Router from 'next/router';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import useUser from 'lib/useUser';
import { bgColor } from 'config/data';

const ModalPurchase = ({
  visible,
  setVisible,
  setNftData,
  nftData = {},
  usdPurchaseData = {},
  access_token,
  wallets,
}) => {
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { colorMode } = useColorMode();
  const wallet = wallets?.filter((el) => el.network === 'stacks');

  const { user } = useUser();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = (e) => {
        // console.log('got event', e);
        if (e.data && e.data.target && e.data.target === 'lnswap') {
          console.log('received data from lnswap widget: ', e.data.data);
          setVisible(true);
          setStep(4);
        }
      };
      window.addEventListener('message', handler);
      // clean up
      return () => window.removeEventListener('message', handler);
    }
  }, []); // empty array => run only once

  useEffect(() => {
    setStep(1);
    setSuccess(false);
  }, []);

  const onSubmitPay = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(3); // 2 red text
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  const onSubmitConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(3);
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  const onSubmitBtn = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(4);
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  const onSubmitFinish = () => {
    setVisible(false);
    setSuccess(false);
    setStep(1);
    if (typeof window !== 'undefined') {
      Router.push('/favorites');
    }
  };

  const onClose = () => {
    setStep(1);
    setSuccess(false);
    setVisible(false);
    setNftData({});
  };

  const onBack = (step) => {
    setStep(step);
  };

  const propsModal = { loading, setVisible, onClose, nftData, usdPurchaseData, onBack, user, access_token, setStep };
  return (
    <Modal isOpen={visible} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        mt={{ base: '30px', md: '236px' }}
        w={{ base: '90%', md: '480px' }}
        bg={bgColor[colorMode]}
        color={colorMode === 'dark' ? 'gray.300' : null}
      >
        <ModalBody p={0}>
          <Fragment>
            {step === 1 && (
              <Step1
                {...propsModal}
                onSubmit={onSubmitPay}
                onSuccess={onSubmitBtn}
                setVisible={setVisible}
                wallet={wallet}
              />
            )}
            {step === 2 && <Step2 {...propsModal} onSubmit={onSubmitConfirm} />}
            {step === 3 && <Step3 {...propsModal} onBack={onBack} onSubmit={onSubmitBtn} />}
            {step === 4 && <Step4 {...propsModal} onSubmit={onSubmitFinish} onClose={onClose} />}
          </Fragment>
          {/* <Flex minH="400px">
            <Loader />
          </Flex> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalPurchase;
