import React, { useState, Fragment, useEffect } from 'react';
import Link from 'next/link';
import {
  Flex,
  Text,
  Button,
  Icon,
  Image,
  Box,
  Alert,
  UnorderedList,
  ListItem,
  Avatar,
  useColorMode,
} from '@chakra-ui/react';
import { purchaseNFT, authenticate, userSession, purchaseNFTLayer } from 'utils/stacks';
import { isEmpty } from 'lodash';
import { MdArrowBack } from 'react-icons/md';
import { VscCircleFilled } from 'react-icons/vsc';
import { deviceType } from 'react-device-detect';
import notification from 'components/Notifications';
import { priceStx, priceUsd } from 'utils/utility';
import useUser from 'lib/useUser';
import fetchJson from 'lib/fetchJson';
import PurchaseCompleted from './completed';
import { API } from 'config/api';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { borderColor } from 'config/data';

const authData = {
  apiLoginID: process.env.NEXT_PUBLIC_LOGIN_ID,
  clientKey: process.env.NEXT_PUBLIC_CLIENT_KEY,
};
const Step1 = ({ loading, onClose, nftData = {}, onSubmit, user, onSuccess, access_token, wallet, setStep }) => {
  const { contract_token_id, price_usd, file, name, price_micro_stx, id, for_sale, priceSTX } = nftData;
  const [isAuthenticated, setIsAuthenticated] = useState(userSession.isUserSignedIn());
  const [txId, setTxId] = useState();
  const [isStxPaymentComplete, setIsStxPaymentComplete] = useState(false);
  const [device, setDevice] = useState('');
  const [intent, setIntent] = useState({});
  const [error, setError] = useState('');
  const [select, setSelect] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState('');
  const { colorMode } = useColorMode();
  const [clientSecret, setClientSecret] = useState(null);

  const { mutateUser } = useUser();

  // useEffect(() => {
  //   if (clientSecret === null) {
  //     setClientSecret(false);
  //     const config = { headers: { Authorization: `Bearer ${access_token}` } };
  //     API.post(`/nft/${id}/purchase/intent/usd`, {}, config)
  //       .then((res) => {
  //         console.log('res', res.data);
  //         setClientSecret(res.data);
  //       })
  //       .catch((e) => setError(e.response.data.message));
  //   }
  // }, [access_token, clientSecret, id]);

  useEffect(() => {
    setSelect(false);
    setDevice(deviceType);
    if (for_sale !== null) {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      API.post(`/nft/${id}/purchase/intent/stx`, {}, config)
        .then((res) => setIntent(res.data))
        .catch((e) => setError(e.response.data.message));
    }
  }, [access_token, for_sale, id, setDevice]);

  const onLoginWallet = async () => {
    authenticate(async () => {
      let userData = userSession.loadUserData();
      try {
        mutateUser(
          await fetchJson('/api/login_wallet', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userData }),
          })
        ).then(() => setIsAuthenticated(true));
      } catch (error) {
        console.error('An unexpected error happened:', error);
      }
    });
  };

  const CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT;

  const onPayLnswap = () => {
    console.log('selectedWallet', selectedWallet);
    if (typeof window !== 'undefined') {
      window.lnswap(
        'swap',
        'triggertransferswap',
        selectedWallet, // User's STX Address
        intent.amount / 1000000 || priceSTX, // STX Amount
        '',
        '',
        'false',
        CONTRACT_ADDRESS,
        intent.memo
      );
    }
  };

  const onSubmitPay = async (values) => {
    console.log('values', values);
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    const dataPurchase = {
      method: 'usd',
      email: values.email,
      amount: clientSecret?.amount / 100,
      intent: clientSecret?.token,
      claim_url: `${process.env.NEXT_PUBLIC_URL}/`,
      payment_token: values?.opaqueData?.dataValue,
    };
    console.log(dataPurchase);
    // API.post(`/nft/${id}/purchase`, dataPurchase, config)
    //   .then((response) => {
    //     console.log('test');
    //     console.log(response);
    //     onSuccess(true);
    //     setLoading(false);
    //     window.gtag('event', 'purchase', {
    //       transaction_id: nanoid(),
    //       value: clientSecret?.amount / 100,
    //       currency: 'USD',
    //       items: [
    //         {
    //           item_id: id,
    //           item_name: name,
    //           currency: 'USD',
    //           price: clientSecret?.amount / 100,
    //           quantity: 1,
    //         },
    //       ],
    //     });
    //   })
    //   .catch((e) => {
    //     setError(e.response.data.message);
    //     console.log('err');
    //   });
  };

  return (
    <Fragment>
      {isStxPaymentComplete ? (
        <PurchaseCompleted onClose={onClose} onSubmit={onClose} />
      ) : select ? (
        <>
          <Flex
            py="12px"
            px="20px"
            borderBottomWidth={1}
            borderColor={borderColor[colorMode]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Icon as={MdArrowBack} onClick={() => setSelect(false)} left="20px" cursor="pointer" fontSize="22px" />
              <Text fontWeight="bold" fontSize="14px" ml="10px">
                Back
              </Text>
            </Flex>
          </Flex>
          <Flex px="20px" pt="15px" pb="30px" flexDirection="column">
            <Text fontSize="24px" fontWeight="bold">
              Select wallet
            </Text>

            <Text fontSize="12px" mb="20px">
              Please select a wallet
            </Text>

            {isEmpty(wallet) ? (
              <Flex flexDirection="column">
                <Text mb="20px" fontSize="16px">
                  Please sync a wallet
                </Text>
                <Link href="/settings?menu=wallets">
                  <a>
                    <Button variant="black" w="100%">
                      Sync wallet
                    </Button>
                  </a>
                </Link>
              </Flex>
            ) : (
              <Flex flexDirection="column">
                {wallet.map((i) => (
                  <Flex
                    key={i.id}
                    alignItems="center"
                    cursor="pointer"
                    mb="15px"
                    borderWidth={1}
                    bg={selectedWallet === i.address ? '#f8f8f8' : ''}
                    borderColor={selectedWallet === i.address ? 'blue' : ''}
                    p="10px"
                    borderRadius={8}
                    onClick={() => setSelectedWallet(i.address)}
                  >
                    <Avatar
                      w="28px"
                      h="28px"
                      mr="10px"
                      size="sm"
                      bg="#7A8087"
                      src={i.network === 'stacks' ? '/images/stacks-stx-logo.svg' : '/images/ethereum-logo.png'}
                    />
                    <Text fontSize="14px">{i.address}</Text>
                  </Flex>
                ))}
                <Button onClick={onPayLnswap} variant="black" mt="13px" isDisabled={!selectedWallet}>
                  <Image w="40px" mr="10px" src="/images/Bitcoin_over_Lightning_Network.svg" alt="" />
                  Pay with Bitcoin via Lightning Network
                </Button>
              </Flex>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Flex
            py="12px"
            px="20px"
            borderBottomWidth={1}
            borderColor={borderColor[colorMode]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Icon as={MdArrowBack} onClick={onClose} left="20px" cursor="pointer" fontSize="22px" />
              <Text fontWeight="bold" fontSize="14px" ml="10px">
                Back
              </Text>
            </Flex>
            {device !== 'mobile' && (
              <Box
                borderRadius="8px"
                p="6px 24px"
                borderColor={colorMode === 'dark' ? '#333' : '#000'}
                borderWidth={1}
                cursor="pointer"
                onClick={user.isLoggedIn ? null : onLoginWallet}
              >
                {user.isLoggedIn ? (
                  <Flex alignItems="center">
                    <Icon as={VscCircleFilled} color="green.500" />
                    <Text fontWeight="bold" fontSize="14px" ml="10px">
                      {user?.username}
                    </Text>
                  </Flex>
                ) : (
                  <Text fontWeight="bold" fontSize="14px">
                    Connect Wallet
                  </Text>
                )}
              </Box>
            )}
          </Flex>
          <Flex px="20px" pt="15px" pb="30px" flexDirection="column">
            <Text fontSize="24px" fontWeight="bold">
              Purchase
            </Text>
            <Text fontSize="12px">NFT Checkout</Text>

            {error && (
              <Alert status="error" mt="20px" fontSize="12px" borderRadius="5px">
                <UnorderedList>
                  {/* {device === 'mobile' && (
                    <ListItem> This NFT can only be purchased with a STX wallet using a desktop computer.</ListItem>
                  )} */}
                  {error && <ListItem> {error}</ListItem>}
                </UnorderedList>
              </Alert>
            )}

            <Flex
              borderWidth={1}
              borderColor={borderColor[colorMode]}
              boxShadow="0 10px 20px 0 rgba(0, 0, 0, 0.05)"
              p="8px"
              mt="30px"
              borderRadius={7}
            >
              <Image
                src={file?.thumbnail_http_url || '/images/no_image.jpg'}
                alt=""
                w="91px"
                h="91px"
                borderRadius="5px"
                objectFit="cover"
              />
              <Flex ml="15px" flexDirection="column" fontSize="12px" id="content" justifyContent="center">
                <Flex id="name" mb="5px">
                  <Text color="gray.500" w="42px">
                    Name
                  </Text>
                  <Text fontWeight="bold">{name || ''}</Text>
                </Flex>

                {/* <Flex id="artist" mb="5px">
                  <Text color="gray.500" w="42px">
                    Description
                  </Text>
                  <Text fontWeight="bold">{description}</Text>
                </Flex> */}

                <Flex id="price">
                  <Text color="gray.500" w="42px">
                    Price
                  </Text>
                  <Text fontWeight="bold">
                    {price_usd && `${priceUsd(price_usd)} / `}
                    {for_sale === null ? priceStx(priceSTX) : priceStx(price_micro_stx || 0)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            {device === 'mobile' && (
              <Flex id="info" alignItems="center" mt="10px">
                <Icon as={BsFillInfoCircleFill} mt="3px" mr="6px" alignSelf="baseline" />
                <Text fontSize="13px">
                  To pay in STX, please purchase this NFT from web browser that supports the Hiro Web Wallet.
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex px="20px" pb="14px" flexDirection="column">
            {price_usd && !error && (
              <Fragment>
                {/* <HostedForm
                  buttonText="Pay USD by Credit Card"
                  authData={authData}
                  onSubmit={(values) => onSubmitPay(values)}
                /> */}

                <Button
                  width="100%"
                  isLoading={loading}
                  type="submit"
                  height="40px"
                  mt="12px"
                  variant="black"
                  _disabled={{ background: '#7f7f7f', color: 'white' }}
                  fontSize="14px"
                  fontWeight="500"
                  _hover={{ bg: '#7f7f7f' }}
                  onClick={onSubmit}
                >
                  Pay USD by Credit Card
                </Button>
              </Fragment>
            )}

            {/* {!isEmpty(wallet) &&
              (for_sale === null ? null : (
                <Button onClick={onPayLnswap} variant="black" mt="13px">
                  <Image w="40px" mr="10px" src="/images/Bitcoin_over_Lightning_Network.svg" alt="" />
                  Pay with Bitcoin via Lightning Network
                </Button>
              ))} */}

            {/* <Button onClick={() => setSelect(true)} variant="black" mt="13px">
              <Image w="40px" mr="10px" src="/images/Bitcoin_over_Lightning_Network.svg" alt="" />
              Pay with Bitcoin via Lightning Network
            </Button> */}

            {device !== 'mobile' &&
              !error &&
              (for_sale === null ? (
                <Button
                  id="contract"
                  variant="outline"
                  mt="15px"
                  fontSize="14px"
                  borderColor="black"
                  onClick={() => {
                    const config = { headers: { Authorization: `Bearer ${access_token}` } };
                    if (userSession.isUserSignedIn()) {
                      const p = new Promise((resolve, reject) => purchaseNFT(contract_token_id, resolve));
                      p.then((txId) => {
                        const dataPurchase = {
                          method: 'contract',
                          email: user.email || '',
                          // intent: intent,
                          claim_url: `${process.env.NEXT_PUBLIC_URL}/`,
                          transaction_id: txId,
                        };
                        API.post(`/nft/${id}/purchase`, dataPurchase, config)
                          .then(() => {
                            onSuccess(true);
                            setTxId(txId);
                            setIsStxPaymentComplete(true);
                            window.gtag('event', 'spend_virtual_currency', {
                              value: priceSTX,
                              virtual_currency_name: 'STX',
                              item_name: name,
                              id,
                            });
                          })
                          .catch((err) => notification('error', `❌${err.response.data.message}`));
                      });
                    } else {
                      authenticate(() => {
                        setIsAuthenticated(true);
                        const p = new Promise((resolve, reject) => purchaseNFT(contract_token_id, resolve));
                        p.then((txId) => {
                          const dataPurchase = {
                            method: 'contract',
                            email: user.email || '',
                            // intent: intent,
                            claim_url: `${process.env.NEXT_PUBLIC_URL}/`,
                            transaction_id: txId,
                          };
                          API.post(`/nft/${id}/purchase`, dataPurchase, config)
                            .then(() => {
                              onSuccess(true);
                              setTxId(txId);
                              setIsStxPaymentComplete(true);
                              window.gtag('event', 'spend_virtual_currency', {
                                value: priceSTX,
                                virtual_currency_name: 'STX',
                                item_name: name,
                                id,
                              });
                            })
                            .catch((err) => notification('error', `❌${err.response.data.message}`));
                        });
                      });
                    }
                  }}
                >
                  Pay with STX
                </Button>
              ) : (
                <Button
                  id="layer"
                  variant="outline"
                  mt="15px"
                  fontSize="14px"
                  borderColor="black"
                  onClick={() => {
                    if (userSession.isUserSignedIn()) {
                      const p = new Promise((resolve, reject) => purchaseNFTLayer(intent.amount, intent.memo, resolve));
                      p.then((txId) => {
                        const dataPurchase = {
                          method: 'stx',
                          email: user.email || '',
                          intent: intent.token,
                          claim_url: `${process.env.NEXT_PUBLIC_URL}/`,
                          transaction_id: txId,
                        };
                        API.post(`/nft/${id}/purchase`, dataPurchase)
                          .then(() => {
                            onSuccess(true);
                            setTxId(txId);
                            setIsStxPaymentComplete(true);
                            window.gtag('event', 'spend_virtual_currency', {
                              value: priceSTX,
                              virtual_currency_name: 'STX',
                              item_name: name,
                              id,
                            });
                          })
                          .catch((err) => notification('error', `❌${err.response.data.message}`));
                      });
                    } else {
                      authenticate(() => {
                        setIsAuthenticated(true);
                        const p = new Promise((resolve, reject) =>
                          purchaseNFTLayer(intent.amount, intent.memo, resolve)
                        );
                        p.then((txId) => {
                          const dataPurchase = {
                            method: 'stx',
                            email: user.email || '',
                            intent: intent.token,
                            claim_url: `${process.env.NEXT_PUBLIC_URL}/`,
                            transaction_id: txId,
                          };
                          API.post(`/nft/${id}/purchase`, dataPurchase)
                            .then(() => {
                              onSuccess(true);
                              setTxId(txId);
                              setIsStxPaymentComplete(true);
                              window.gtag('event', 'spend_virtual_currency', {
                                value: priceSTX,
                                virtual_currency_name: 'STX',
                                item_name: name,
                                id,
                              });
                            })
                            .catch((err) => notification('error', `❌${err.response.data.message}`));
                        });
                      });
                    }
                  }}
                >
                  Pay with STX
                </Button>
              ))}
          </Flex>
        </>
      )}
    </Fragment>
  );
};
export default Step1;
